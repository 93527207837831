/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/20/2019
 * @Example 
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ButtonBasic from "./button-basic";
import Fab from "@material-ui/core/Fab";
import {Image} from "react-bootstrap";
import InlineSVG from "react-inlinesvg";
import ReactLoading from "react-loading";

class ButtonGeneral extends Component {

    getIconButton = () => {
        const {typeButton, icon, typeIcon, iconProps} = this.props;
        let classIcon = iconProps && iconProps.className ? iconProps.className : "";
        let cIcon = "";
        let _icon = "";
        switch (typeButton) {
            case "add":
                _icon = typeof typeIcon === "undefined" || typeIcon === "svg"
                    ? <InlineSVG className={'btn-icon-svg'} src={icon ? icon : require('./icons/add.svg')} {...iconProps} />
                    : <Image src={icon ? icon : require('./icons/add.svg')} {...iconProps} />;
                return {icon: _icon};
            case "edit":
                _icon = typeof typeIcon !== "undefined" || typeIcon === "svg"
                    ? <InlineSVG className={'btn-icon-svg'} src={icon ? icon : require('./icons/edit.svg')} {...iconProps} />
                    : <Image src={icon ? icon : require('./icons/edit.svg')} {...iconProps} />;
                return {icon: _icon};
            case "delete":
                cIcon = icon ? icon : "fas fa-trash-alt";
                return {icon: <i {...iconProps} className={classIcon + " " + cIcon} />};
            case "save":
                _icon = typeof typeIcon === "undefined" || typeIcon === "svg"
                    ? <InlineSVG className={'btn-icon-svg'} src={icon ? icon : require('./icons/save.svg')} {...iconProps} />
                    : <Image src={icon ? icon : require('./icons/save.svg')} {...iconProps} />;
                return {icon: _icon, colorBtn: 'primary'};
            case "notsave":
                classIcon = classIcon ? classIcon : "";
                cIcon = icon ? icon : "glyphicon glyphicon-floppy-remove";
                return {icon: <span {...iconProps} className={classIcon + " " + cIcon} />, colorBtn: 'secondary'};
            case "excel":
                _icon = typeof typeIcon === "undefined" || typeIcon === "svg"
                    ? <InlineSVG className={'btn-icon-svg'} src={icon ? icon : require('./icons/excel.svg')} {...iconProps} />
                    : <Image src={icon ? icon : require('./icons/excel.svg')} {...iconProps} />;
                return {icon: _icon};
            case "help":
                cIcon = icon ? icon : "fas fa-question-circle";
                return {icon: <i {...iconProps} className={classIcon + " " + cIcon} />};
            case "close" :
                cIcon = icon ? icon : "fas fa-times";
                return {icon: <i {...iconProps} className={classIcon + " " + cIcon} />};
            case "filter":
                cIcon = icon ? icon : "fas fa-filter";
                return {icon: <i {...iconProps} className={classIcon + " " + cIcon} />};
            case "search":
                cIcon = icon ? icon : "fas fa-search";
                return {icon: <i {...iconProps} className={classIcon + " " + cIcon} />};
            case "approve":
                cIcon = icon ? icon : "fas fa-check";
                return {icon: <i {...iconProps} className={classIcon + " " + cIcon} />};
            case "view":
                cIcon = icon ? icon : "fas fa-eye";
                return {icon: <i {...iconProps} className={classIcon + " " + cIcon} />};
            case "send":
                cIcon = icon ? icon : "fas fa-paper-plane";
                return {icon: <i {...iconProps} className={classIcon + " " + cIcon} />};
            default:
                return "";
        }
    };

    onClick() {
        const {loading} = this.props;
        if(this.props.onClick && !loading) this.props.onClick();
    }

    render() {
        const {fab, color, name, className, disabled, variant, margin, loading, ...props} = this.props;

        const {icon, colorBtn} = this.getIconButton();
        const _color = color ? color : colorBtn;
        const styleFab = {
            backgroundColor: '#fff'
        };
        if (props.typeButton) delete props.typeButton;
        if (props.typeIcon) delete props.typeIcon;

        return (
            <React.Fragment>
                {!fab && <ButtonBasic  {...props}
                                       disabled={disabled}
                                       style={props.style ? props.style : _color ? {} : styleFab}
                                       color={_color}
                                       className={(className ? className : '') + " btn-action" + (loading ? " btn-loading" : "")}
                                       onClick={() => this.onClick()}
                                       variant={variant ? variant : "outlined"}
                                       margin={margin ? margin : "dense"}
                >
                    {(!loading && icon) && icon}
                    {loading && <ReactLoading className={name ? "mgr5" : ""} type={'spokes'} height={16} width={16} color={_color !== 'inherit' ? '#fafafa' : '#3c3c3c'} />}
                    {name && <span className={icon ? "mgl5" : ""}>{name}</span>}
                    {!name && <span style={{marginLeft: -5}}>&nbsp;</span>}
                </ButtonBasic>}
                {fab && <Fab
                    {...props}
                    disabled={disabled}
                    style={props.style ? props.style : _color ? {} : styleFab}
                    color={_color}
                    className={className + (loading ? " btn-loading" : "")}
                    onClick={()=>this.onClick()}
                    variant={variant ? variant : 'extended'}
                >
                    {(!loading && icon) && icon}
                    {loading && <ReactLoading className={name ? "mgr5" : ""} type={'spokes'} height={16} width={16} color={_color !== 'inherit' ? '#fafafa' : '#3c3c3c'} />}
                    {name && <span className={icon ? "mgl5" : ""}>{name}</span>}
                    {!name && <span style={{marginLeft: -5}}>&nbsp;</span>}
                </Fab>}
            </React.Fragment>
        );
    }
}

ButtonGeneral.propTypes = {
    fab: PropTypes.bool,
    typeButton: PropTypes.string,
    typeIcon: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    color: PropTypes.string,
    variant: PropTypes.string,
    margin: PropTypes.string,
    className: PropTypes.string,
    iconProps: PropTypes.any,
    loading: PropTypes.bool,

    onClick: PropTypes.func,
};

export default ButtonGeneral;