/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/01/12 10:37
 * @update 2019/01/12 10:37
 * @file src/container/index.js
 */

import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';

import * as mainActions from '../redux/main/main_actions';
import Popup from '../components/popup/popup';
import Config from '../config';
import Header from '../components/header/header';
import LoginPage from "../components/login/login";
import {browserHistory} from "react-router";
import ReactLoading from "react-loading";
import LocalizedStrings from "react-localization";
import Controller from "../components/common/helpers/controller";
import 'antd/dist/antd.css';
import AuthPage from "../components/login/auth";
import Maintenance         from "../components/common/layouts/maintenance";
import moment              from "moment";
import {localize}          from "../localize/localize";

class Index extends Component {


    constructor(props){
        super(props);

        this.state = {
            expandSideBar: false,
            opened: true,
            isTab: Config.menuType
        };
        this.loading = true;
    }

    componentDidMount = async () => {
        const {children} = this.props;
        Config.setLangDevextreme();
        this.loadLocalize();
        await this.changedRouterEvent();

        const pathname = children.props.route && children.props.route.path ? children.props.route.path : "";
        const isAdmin = pathname && pathname.indexOf("admin") !== -1;
        const stAdmin = Config.getLocalStorage('STADMINBI');
        if (!isAdmin && stAdmin) {
            Config.setLocalStorage('STADMINBI', '');
            Config.logout();
            return false;
        }
        this.redirectPage();
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        const {iPermission} = this.props;
        const _loading = document.getElementById("_preloader_loading");
        if (_loading) {
            _loading.style.display = !!iPermission ? "none" : "block";
        }
    }

    /**
     * func update fav icon from system LOGO_URL
     */
    setFaviconEl = () => {

        // get element favicon
        let svg = Config.getLocalStorage("LOGO_URL");

        // check setting change logo
        if(svg && svg === Config.getSetting('LOGO_URL')) return;

        // // save LOGO URL to LocalStorage
        svg = Config.getSetting("LOGO_URL");
        Config.setLocalStorage("LOGO_URL", svg);

        // update fav icon
        const link = document.querySelector('#favicon');
        link.href = svg;

    };

    redirectPage = () => {
        let pathinfo = Config.getLocalStorage('AUTHREDIRECTBI');
        if (pathinfo) {
            pathinfo = JSON.parse(pathinfo);
            Config.removeLocalStorage('AUTHREDIRECTBI');
            browserHistory.push({
                pathname: pathinfo.pathname || "",
                state: pathinfo.state
            });
        }
    };

    changedRouterEvent = async () => {
        const pathname = window.location.pathname;
        const formID = Config.helpers.getFormIDFromPath(pathname);
        Config.formID = formID;
        Config.setLocalStorage('formActive', JSON.stringify(Config.helpers.updateCurrentForm(formID)));
        await browserHistory.listen( async (location) =>  {
            const formID = Config.helpers.getFormIDFromPath(location.pathname);
            Config.formID = formID;
            Config.setLocalStorage('formActive', JSON.stringify(Config.helpers.updateCurrentForm(formID)));
        });
    };

    loadLocalize = () => {
        let cfLocalize = null;
        let lang = Config.getLocalStorage("langBI");

        if (!lang || lang.length !== 2){
            lang = "vi";
            Config.setLocalStorage('langBI','vi');
        }

        try {
            const lc = Config.getLocalStorage('LOCALIZE');
            cfLocalize = lc ? JSON.parse(lc) : null;

            if (!cfLocalize || cfLocalize.timestamps !== localize.timestamps) {
                cfLocalize = localize;
                Config.setLocalStorage('LOCALIZE',JSON.stringify(cfLocalize));
            }

        } catch (e) {

        }

        Config.localization = new LocalizedStrings(cfLocalize);
        Config.localization.setLanguage(lang);

        if (lang === "vi"){
            Config.language = "84";
        } else {
            Config.language = "01";
        }
    };

    componentWillMount(){
        this.props.mainActions.loading();
        this.setState({isTab: Config.menuType});
    }

    onLogout = () => {
        Config.logout();
    };

    checkMaintenance = () => {
        const keyFrom = "DateFrom";
        const keyTo = "DateTo";
        const {maintenance} = this.props;
        let result = null;
        if (maintenance && maintenance[keyFrom]) {
            const now = moment();
            //Check current time greater than from time..
            const checkFrom = now.diff(moment(maintenance[keyFrom])) >= 0;
            //Check has't to or current time less than to time..
            const checkTo = !maintenance[keyTo] || now.diff(moment(maintenance[keyTo])) <= 0;
            result = checkFrom && checkTo ? maintenance : null;
        }
        return result
    };

    render() {
        const {children, token, setting, profile} = this.props;
        const {isTab} = this.state;

        let permission = false;

        if(token && token.id && setting && setting.length > 0) {
            this.loading = false;
        }
        if(setting) this.setFaviconEl();
        if(profile && profile.type !== 0) {
            permission = profile.type;
        }
        const listExpandContent = ["W75F2000", "W09F2000"];
        const pathname = children.props.route.path;
        this.isAdmin = pathname && pathname.indexOf("admin") !== -1;

        // check maintenance
        let maintenance = null;
        const url = new window.URLSearchParams(window.location.search);
        let pass = url.get('pass');
        let localDeployer = Config.getLocalStorage('DEPLOYER_SESSION');
        localDeployer = localDeployer && Config.isJson(localDeployer) ? JSON.parse(localDeployer) : null;

        if(!pass && localDeployer?.pass && moment(localDeployer.time).isAfter(moment())){
          pass = localDeployer?.pass;
        }

        if(Config.deployer !== pass){
            maintenance = this.checkMaintenance();
        }

        if(pass){
          Config.setLocalStorage('DEPLOYER_SESSION', JSON.stringify({pass:pass, time: moment().add(4,'hours')}))
        }

        if((!pass && localDeployer) || (maintenance?.DateTo && moment(maintenance.DateTo).isBefore(moment()))){
          Config.removeLocalStorage('DEPLOYER_SESSION');
        }

        if(this.isAdmin){
            window.location.replace(`${Config.env?.api}/admin`);
        }


        return (
            <div className="display_col full_w">
                <Controller ref={(ref) => Config.controller = ref} />
                {this.loading && pathname !== "auth" && (
                    <div className="loading">
                        <div className={'display_row align-center valign-middle'} style={{height: '100%'}}>
                            <ReactLoading type={'spin'}
                                          color="#6FA3F8"
                            />
                        </div>
                    </div>
                )}

                {!this.isAdmin && pathname === "auth" &&
                    <div className="display_col">
                        <AuthPage loading={this.loading}/>
                    </div>
                }

                {!this.isAdmin && maintenance &&
                    <div className="display_col">
                        <Maintenance data={maintenance}/>
                    </div>
                }

                {!this.isAdmin && !maintenance ? (
                    <>
                        {!this.isAdmin && !this.loading && !permission && pathname !== "report" &&
                            <div className="display_col">
                                <LoginPage/>
                            </div>
                        }


                        {!this.isAdmin && ((!this.loading && pathname === "report") || (profile && !this.loading && permission)) &&
                            <div className="body-container">
                                <div className="body-container_right display_col">
                                    <Header onLogout={this.onLogout}
                                            isTab={isTab}
                                            {...this.props}
                                    />
                                    <div className={'display_row side-nav'}>
                                        <div className={"content-container" + (listExpandContent.indexOf(pathname) > -1 ? " expand" : "")}>
                                            {children}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {!this.loading && pathname === "error-page" && !profile && !permission &&
                        <div className="body-container">
                            <div className="body-container_right display_col">
                                {children}
                            </div>
                        </div>
                        }
                    </>
                ) : null}

                <Popup ref={(ref)=>Config.popup = ref} />
            </div>
        );
    }
}

export default connect(state => ({
        token: state.main.token,
        setting: state.main.setting,
        profile: state.main.profile,
        maintenance: state.main.maintenance,
        iPermission: state.general.iPermission,
    }),
    (dispatch) => ({
        mainActions: bindActionCreators(mainActions, dispatch)
    })
)(Index);
