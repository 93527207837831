/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/9/2019
 * @Example
 */

import React, {Component} from 'react';
import { connect } from 'react-redux';

import {createMuiTheme, MuiThemeProvider} from "@material-ui/core";

class ThemeWrapper extends Component {

    setColorScssStatic = (color) => {
        if (!color) return false;
        if (color.SubColor) {
            document.body.style.setProperty('--primary-bg-color-light', color.SubColor);
        }
        if (color.MainColor) {
            document.body.style.setProperty('--primary-bg-color', color.MainColor);
        }
        if (color.contrastText) {
            document.body.style.setProperty('--primary-font-color', color.contrastText);
        }
        if (color.SecondaryColor) {
            document.body.style.setProperty('--secondary-bg-color', color.SecondaryColor);
        }
    };

    render() {
        const {color} = this.props;
        this.setColorScssStatic(color);
        const theme = createMuiTheme({
            palette: {
                primary: {
                    light: color && color.SubColor ? color.SubColor : '#FFF1FF', //default light
                    main: color && color.MainColor ? color.MainColor : '#5A003C', //default
                    contrastText: color && color.contrastText ? color.contrastText : '#fafafa', //text default
                },
                secondary: {
                    main: color && color.SecondaryColor ? color.SecondaryColor : '#f44336', //phan dien
                },
            },
            props: {
                MuiTextField: {
                    margin: 'dense',
                },
            },
            overrides: {
                MuiTextField: {
                    root: {
                        backgroundColor: '#fff'
                    }
                },
                MuiFormControlLabel: {
                    root: {
                        marginLeft: 0
                    }
                },
                MuiTab: {
                    root: {
                        fontSize: '1rem !important',
                    },
                },
                MuiOutlinedInput: {
                    root: {
                        height: '100%'
                    }
                },
                MuiSelect: {
                    icon: {
                        top: 'calc(50% - 14px)'
                    },
                    iconOutlined: {
                        right: 0
                    }
                },
                MuiCardContent: {
                    root: {
                        padding: 15
                    }
                },
                MuiFormHelperText: {
                    root: {
                        position: "absolute",
                        bottom: '-13px',
                        left: 0,
                        fontSize: 11
                    }
                },
                MuiAutocomplete: {
                    popup: {
                        zIndex: 10000
                    }
                },
                PrivateNotchedOutline: {
                    legend: {
                        border: 'none'
                    }
                }
            },
            typography: {
                fontFamily: "'SF Display','Font Awesome 5 Free',sans-serif",
                textTransform: "none",
                fontSize: 14,
                button: {
                    textTransform: 'none',
                },
                input: {
                    padding: 'inherit'
                },
            }
        });

        return (
            <MuiThemeProvider theme={theme}>
                <div>
                    { this.props.children }
                </div>
            </MuiThemeProvider>
        )
    }
}

export default connect(state => ({
        color: state.main.color,
    })
)(ThemeWrapper);
